import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './Components/locals/en.json';
import fr from './Components/locals/fr.json';
import it from './Components/locals/it.json';
import es from './Components/locals/es.json';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: en },
      fr: { translation: fr },
      it: { translation: it },
      es: { translation: es },
    },
    lng: 'fr', // default language
    fallbackLng: 'fr', // fallback language if the selected language's keys are missing
    interpolation: {
      escapeValue: false, // React already escapes values
    },
  });

export default i18n;
