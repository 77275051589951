import React from "react";
import { useTranslation } from "react-i18next";
import "./Services.css";
import { FaBusinessTime } from "react-icons/fa";
import { MdEmojiEvents, MdWorkspacePremium, MdOutlineConnectingAirports } from "react-icons/md";
import { BiTrip } from "react-icons/bi";
import { Ri24HoursLine } from "react-icons/ri";

const Services = () => {
  const { t } = useTranslation();

  return (
    <div className="container pt-5">
      <p className="text-white mt-5 bg-info rounded-pill w-50 p-2 m-auto fw-bold mb-4">
        {t("services")}
      </p>
      <h2 className="text-info">{t("exclusive_experience")}</h2>
      <p className="text-white">{t("high_quality")}</p>

      {/* First Row */}
      <div className="row g-4">
        {/* Airport Transfers */}
        <div className="col-lg-4">
          <div className="card m-auto h-100 text-white bg-dark mb-3" style={{ maxWidth: "18rem" }}>
            <div className="card-header">
              <MdOutlineConnectingAirports className="icon-service" /> {t("airport_transfers")}
            </div>
            <div className="card-body">
              <h6>{t("airport_service")}</h6>
              <ul className="check-list">
                <li>{t("fast_reliable")}</li>
                <li>{t("affordable")}</li>
                <li>{t("available_24_7")}</li>
                <li>{t("comfortable_rides")}</li>
              </ul>
            </div>
          </div>
        </div>

        {/* Business Transport */}
        <div className="col-lg-4">
          <div className="card m-auto h-100 text-white bg-dark mb-3" style={{ maxWidth: "18rem" }}>
            <div className="card-header">
              <FaBusinessTime className="icon-service" /> {t("business_transport")}
            </div>
            <div className="card-body">
              <h6>{t("business_transport")}</h6>
              <ul className="check-list">
                <li>{t("free_wifi")}</li>
                <li>{t("bilingual_driver")}</li>
                <li>{t("company_billing")}</li>
              </ul>
            </div>
          </div>
        </div>

        {/* Special Events */}
        <div className="col-lg-4">
          <div className="card m-auto h-100 text-white bg-dark mb-3" style={{ maxWidth: "18rem" }}>
            <div className="card-header">
              <MdEmojiEvents className="icon-service" /> {t("special_events")}
            </div>
            <div className="card-body">
              <h6>{t("prestige_transport")}</h6>
              <ul className="check-list">
                <li>{t("decorated_vehicles")}</li>
                <li>{t("champagne_on_request")}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* Second Row */}
      <div className="row g-4">
        {/* Private Tours */}
        <div className="col-lg-4">
          <div className="card m-auto h-100 text-white bg-dark mb-3" style={{ maxWidth: "18rem" }}>
            <div className="card-header">
              <BiTrip className="icon-service" /> {t("private_tours")}
            </div>
            <div className="card-body">
              <h6>{t("discover_region")}</h6>
              <ul className="check-list">
                <li>{t("custom_itineraries")}</li>
                <li>{t("multilingual_guide")}</li>
                <li>{t("photo_stops")}</li>
              </ul>
            </div>
          </div>
        </div>

        {/* 24/7 Service */}
        <div className="col-lg-4">
          <div className="card h-100 m-auto text-white bg-dark mb-3" style={{ maxWidth: "18rem" }}>
            <div className="card-header">
              <Ri24HoursLine className="icon-service " /> {t("service_24_7")}
            </div>
            <div className="card-body">
              <h6>{t("service_24_7")}</h6>
              <ul className="check-list">
                <li>{t("instant_booking")}</li>
                <li>{t("phone_support")}</li>
                <li>{t("maximum_flexibility")}</li>
              </ul>
            </div>
          </div>
        </div>

        {/* Premium Comfort */}
        <div className="col-lg-4">
          <div className="card h-100 m-auto text-white bg-dark mb-3" style={{ maxWidth: "18rem" }}>
            <div className="card-header">
              <MdWorkspacePremium className="icon-service" /> {t("premium_comfort")}
            </div>
            <div className="card-body">
              <h6>{t("luxury_conditions")}</h6>
              <ul className="check-list">
                <li>{t("high_end_vehicles")}</li>
                <li>{t("air_conditioning")}</li>
                <li>{t("leather_seats")}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
