import React from 'react';
import ReactDOM from 'react-dom/client';
import ReactGA from 'react-ga4';  // Import react-ga4
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import "bootstrap/dist/css/bootstrap.min.css"
import "./i18n"; //  Make sure this matches the location of your i18n.js
import "bootstrap/dist/js/bootstrap.min.js"
import "bootstrap/dist/js/bootstrap.bundle.min.js"; // Ensure this is imported

// Initialize GA4 with your tracking ID
ReactGA.initialize('G-3TV1VMSZ3F');  // Replace with your GA4 tracking ID


// Track initial pageview
ReactGA.send('pageview', window.location.pathname + window.location.search);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
<BrowserRouter>
<App /></BrowserRouter>
  </React.StrictMode>
);

