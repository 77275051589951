import React, { useEffect } from "react";
import "./Merci.css";

const Merci = () => {
  useEffect(() => {
    // Load Google Analytics script
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://www.googletagmanager.com/gtag/js?id=AW-123456789"; // ✅ Replace with your actual ID
    document.head.appendChild(script);

    // Initialize Google Analytics
    const gtagScript = document.createElement("script");
    gtagScript.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){ dataLayer.push(arguments); }
      gtag('js', new Date());
      gtag('config', 'AW-123456789'); // ✅ Replace with your actual ID
    `;
    document.head.appendChild(gtagScript);
  }, []);

  return (
    <div className="merci-container">
      <div className="merci-content">
        <h1 className="thankyou-text">Merci pour votre soumission !</h1>
        <h2 className="thankyou-subtext">Thank You for Your Submission!</h2>
        <p className="confirmation-text">
          Votre demande a été enregistrée avec succès. Nous vous contacterons sous peu.
        </p>
        <p className="confirmation-text">
          Your request has been successfully recorded. We will contact you shortly.
        </p>
      </div>
    </div>
  );
};

export default Merci;
