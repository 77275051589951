import { Link } from "react-router-dom";
import "./NotFoundPage.css";

const NotFoundPage = () => {
    return (
        <div className="container-notFound">
            <h1 className="errorCode">404</h1>
            <p className="message">Oops! The page you're looking for doesn't exist.</p>
            <Link to="/" className="homeButton">Go Home</Link>
        </div>
    );
};

export default NotFoundPage;