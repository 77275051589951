import React from "react";
import { FaFacebookF, FaInstagram } from "react-icons/fa";
import { BsTelephoneFill, BsEnvelopeFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import { AiFillYoutube } from "react-icons/ai";
import { useTranslation } from "react-i18next"; 
import "./Footer.css";

const Footer = () => {
  const { t } = useTranslation(); 

  return (
    <footer className="footer mt-5 pt-5 bg-dark text-light py-4">
      <div className="container">
        <div className="row text-center">
          {/* Contact Section */}
          <div className="col-lg-4 text-start footer-box">
            <h4>{t("contact")}</h4>
            <p>
              <BsTelephoneFill className="footer-icon" />  
              <Link to="tel:+33 661585885" className="text-decoration-none text-white">
              +33 661585885
              </Link>
            </p>
            <p>
              <BsEnvelopeFill className="footer-icon" />  
              <Link to="mailto:taxiscab1@gmail.com" className="text-decoration-none text-white">
              taxiscab1@gmail.com
              </Link>
            </p>
          </div>

          {/* Navigation Section */}
          <div className="col-lg-4 text-start footer-box">
            <h4>{t("navigation")}</h4>
            <ul className="footer-links">
              <li><Link to="/">{t("home")}</Link></li>
              <li><Link to="/services">{t("services")}</Link></li>
              <li><Link to="/contact">{t("booking")}</Link></li>
            </ul>
          </div>

          {/* Social Media Section */}
          <div className="col-lg-4 footer-box">
            <h4 className="text-center">{t("follow_us")}</h4>
            <div className="social-icons">
              <Link to="https://www.facebook.com" className="social-icon"><FaFacebookF /></Link>
              <Link to="https://www.youtube.com" className="social-icon"><AiFillYoutube /></Link>
              <Link to="https://www.instagram.com" className="social-icon"><FaInstagram /></Link>
            </div>
          </div>
        </div>

        {/* Footer Bottom */}
        <div className="text-center mt-3">
          <p className="footer-text">{t("copyright")}</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
