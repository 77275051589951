import React from "react";
import { useTranslation } from "react-i18next";
import "./About.css";
import logo from "./logo.png";

const About = () => {
  const { t } = useTranslation();

  return (
    <div className="about-container">
      <div className="about-content">
        <img src={logo} alt="car" className="profile-pic" />
        <div className="text-content">
          <h1>{t("about_us")}</h1>
          <h2>{t("private_driver")}</h2>
          <p><strong>Taxiscab</strong>, {t("welcome_message")}</p>
          <p>{t("service_description")}</p>
          <p className="signature">{t("signature")}</p>
        </div>
      </div>
    </div>
  );
};

export default About;
