import "./App.css";
import About from "./Components/About/About";
import Home from "./Components/Home/Home";
import Navbar from "./Components/Navbar/Navbar";
import { Route, Routes, useLocation } from "react-router-dom";
import Services from "./Components/Services/Services";
import Cities from "./Components/Cities/Cities";
import Contact from "./Components/Contact/Contact";
import Footer from "./Components/Footer/Footer";
import ReactGA from "react-ga4"; // Import react-ga4
import { useEffect } from "react"; // Import useEffect for tracking
import NotFoundPage from "./Components/NotfoundPage/ NotFoundPage";
import Merci from "./Components/Merci/Merci";
import { useTranslation } from "react-i18next";
function App() {
  const location = useLocation(); // Use location to track route changes
  const {  i18n } = useTranslation();

  // Track page views when the route changes
  useEffect(() => {
    ReactGA.send("pageview", location.pathname + location.search); // Send page view to Google Analytics
  }, [location]); // Dependency on location ensures it runs on every route change

  return (
    <div className="App">
      <Navbar />
  
      <Routes key={i18n.language}  >
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/cities" element={<Cities />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/footer" element={<Footer />} />
        <Route path="*" element={<NotFoundPage />} />
        <Route path="/merci" element={<Merci />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
