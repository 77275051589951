import React from 'react';
import "./Home.css";
import { Link } from 'react-router-dom';
import { BsFillTelephoneInboundFill } from "react-icons/bs";
import Services from '../Services/Services';
import Cities from '../Cities/Cities';
import Contact from '../Contact/Contact';
import { useTranslation } from 'react-i18next'; // Import the hook

const Home = () => {
  const { t } = useTranslation(); // Use the translation hook


  return (
    <div className='container-fluid p-0 m-0'>


      <div id="carouselExampleFade" className="carousel slide carousel-fade" data-bs-ride="carousel">
        <div className="carousel-inner">
          {/* Slide 1 */}
          <div className="carousel-item active">
            <img className="d-block w-100 opacity-75 img-fluid" loading='lazy' src="/assets/dooropentaxi.jpg" alt="First slide" />
            <div className="carousel-caption d-block text-center">
              <h3 className='fw-bold' style={{ color: "#E67E22 " }}>
                {t('carousel1_title')}
              </h3>
              <h5 className='text-light'>
                {t('carousel1_subtitle')}
              </h5>
              <p className='text-bold' style={{ fontSize: "20px" }}>
                {t('carousel1_text')}
              </p>
              <div className="d-flex flex-wrap justify-content-center gap-3 mt-3">
                <Link to="tel:+33661585885" className="btn w-75 bg-warning btn-outline-warning text-dark px-4">
                  <BsFillTelephoneInboundFill /> 0661585885
                </Link>
                <Link to="/contact" className="btn w-50 fw-bold btn-outline-success text-warning px-4">
                  {t('reserve_now')}
                </Link>
              </div>
            </div>
          </div>

          {/* Slide 2 */}
          <div className="carousel-item">
            <img className="d-block w-100 opacity-75 img-fluid" loading='lazy' src="/assets/baggaeunloaded.jpg" alt="Second slide" />
            <div className="carousel-caption d-block text-center">
              <h3 className='fw-bold' style={{ color: "#E67E22 " }}>
                {t('carousel2_title')}
              </h3>
              <p>{t('carousel2_text')}</p>
              <Link to="/services" className="btn btn-dark btn-outline-light">
                {t('learn_more')}
              </Link>
            </div>
          </div>

          {/* Slide 3 */}
          <div className="carousel-item">
            <img className="d-block w-100 opacity-75  img-fluid" loading='lazy' src="/assets/musictaxi.jpg" alt="Third slide" />
            <div className="carousel-caption d-block text-center">
              <h3 className='fw-bold' style={{ color: "#E67E22 " }}>
                {t('carousel3_title')}
              </h3>
              <p>{t('carousel3_text')}</p>
              <Link to="/book" className="btn btn-outline-light">{t('contact_us')}</Link>
            </div>
          </div>

        </div>

        {/* Controls */}
        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>


      <Services />
      <Cities />
      <Contact />

    </div>
  );
};

export default Home;
