import React from 'react';
import { CiLocationOn } from "react-icons/ci";
import "./Cities.css"
import { useTranslation } from "react-i18next";
const Cities = () => {
  const { t } = useTranslation();
  return (
    <div className='container pt-5'> 
      <p className='text-white bg-info mt-5 rounded-pill w-50 m-auto fw-bold  p-2 mb-4 '>        {t("served_cities")}
      </p>

      <div className="container-cities">
        {/* Row 1 */}
        <div className="row w-75 m-auto">
          <div className="col-lg-4 col-md-6">
            <CiLocationOn className="icon-city"    /> Taxi Antibes
          </div>
          <div className="col-lg-4 col-md-6">
            <CiLocationOn className="icon-city"    /> Taxi Beaudolei
          </div>
          <div className="col-lg-4 col-md-6">
            <CiLocationOn className="icon-city"    /> Taxi Biot
          </div>
        </div>
        {/* Row 2 */}
        <div className="row w-75 m-auto">
          <div className="col-lg-4 col-md-6">
            <CiLocationOn className="icon-city"    /> Taxi Cagnes-sur-Mer
          </div>
          <div className="col-lg-4 col-md-6">
            <CiLocationOn className="icon-city"    /> Taxi Cannes
          </div>
          <div className="col-lg-4 col-md-6">
            <CiLocationOn className="icon-city"    /> Taxi Carros
          </div>
        </div>
        {/* Row 3 */}
        <div className="row w-75 m-auto">
          <div className="col-lg-4 col-md-6">
            <CiLocationOn className="icon-city"    /> Taxi Colle-sur-Loup
          </div>
          <div className="col-lg-4 col-md-6">
            <CiLocationOn className="icon-city"    /> Taxi Cogolin
          </div>
          <div className="col-lg-4 col-md-6">
            <CiLocationOn className="icon-city"    /> Taxi Grasse
          </div>
        </div>
        {/* Row 4 */}
        <div className="row w-75 m-auto">
          <div className="col-lg-4 col-md-6">
            <CiLocationOn className="icon-city"    /> Taxi La Gaude
          </div>
          <div className="col-lg-4 col-md-6">
            <CiLocationOn className="icon-city"    /> Taxi La Gattieres
          </div>
          <div className="col-lg-4 col-md-6">
            <CiLocationOn className="icon-city"    /> Taxi Le Cannet
          </div>
        </div>
        {/* Row 5 */}
        <div className="row w-75 m-auto">
          <div className="col-lg-4 col-md-6">
            <CiLocationOn className="icon-city"    /> Taxi Menton
          </div>
          <div className="col-lg-4 col-md-6">
            <CiLocationOn className="icon-city"    /> Taxi Mouans-Sartoux
          </div>
          <div className="col-lg-4 col-md-6">
            <CiLocationOn className="icon-city"    /> Taxi Nice
          </div>
        </div>
        {/* Row 6 */}
        <div className="row w-75 m-auto">
          <div className="col-lg-4 col-md-6">
            <CiLocationOn className="icon-city"    /> Taxi Pymeinade
          </div>
          <div className="col-lg-4 col-md-6">
            <CiLocationOn className="icon-city"    /> Taxi Ramatuelle
          </div>
          <div className="col-lg-4 col-md-6">
            <CiLocationOn className="icon-city"    /> Taxi Roquefort-les-Pins
          </div>
        </div>
        {/* Row 7 */}
        <div className="row w-75 m-auto">
          <div className="col-lg-4 col-md-6">
            <CiLocationOn className="icon-city"    /> Taxi Roquebrune-Cap-Martin
          </div>
          <div className="col-lg-4 col-md-6">
            <CiLocationOn className="icon-city"    /> Taxi Saint-Maxime
          </div>
          <div className="col-lg-4 col-md-6">
            <CiLocationOn className="icon-city"    /> Taxi Saint-Paul-de-Vence
          </div>
        </div>
        {/* Row 8 */}
        <div className="row w-75 m-auto">
          <div className="col-lg-4 col-md-6">
            <CiLocationOn className="icon-city"    /> Taxi Trinite
          </div>
          <div className="col-lg-4 col-md-6">
            <CiLocationOn className="icon-city"    /> Taxi Vallauris
          </div>
          <div className="col-lg-4 col-md-6">
            <CiLocationOn className="icon-city"    /> Taxi Villeneuve-Loubet
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cities;
