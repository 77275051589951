import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { BsFillTelephoneFill } from "react-icons/bs";
import { useTranslation } from "react-i18next"; // Import i18next hook
import "./Navbar.css";

const Navbar = () => {
  const { t, i18n } = useTranslation(); // Use the i18next hook
  const [navColor, setNavColor] = useState(false);
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);

  // Change Navbar Background on Scroll
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setNavColor(true);
      } else {
        setNavColor(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Toggle Navbar for Mobile Menu
  const toggleNavbar = () => {
    setIsNavCollapsed(!isNavCollapsed);
  };

  // Close Navbar when clicking a link
  const closeNavbar = () => {
    setIsNavCollapsed(true);
  };

  // Handle language change
  const handleLanguageChange = (lang) => {
    i18n.changeLanguage(lang); // Change the language when a flag is clicked
  };

  return (
    <nav
      className={`navbar navbar-expand-lg fixed-top ${
        navColor ? "scrolled" : ""
      }`}
    >
      <div className="container-fluid">
        <Link className="navbar-brand" to="/">
          Taxiscab
        </Link>
                 {/* Language Switcher with Emoji Flags */}
                 <div className="d-flex  border-rounded  w-25  justify-content-end gap-2 align-items-center">
            <span
              onClick={() => handleLanguageChange('fr')}
              style={{ cursor: 'pointer' }}
            >
              🇫🇷
            </span>
            <span
              onClick={() => handleLanguageChange('en')}
              style={{ cursor: 'pointer' }}
            >
              🇬🇧
            </span>
            <span
              onClick={() => handleLanguageChange('it')}
              style={{ cursor: 'pointer' }}
            >
              🇮🇹
            </span>
            <span
              onClick={() => handleLanguageChange('es')}
              style={{ cursor: 'pointer' }}
            >
              🇪🇸
            </span>
          </div>
        <button
          className="navbar-toggler"
          type="button"
          onClick={toggleNavbar}
          aria-controls="navbarSupportedContent"
          aria-expanded={!isNavCollapsed}
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className={`${isNavCollapsed ? "collapse" : ""} navbar-collapse`}
          id="navbarSupportedContent"
        >
          <ul className="navbar-nav m-auto mb-2 mb-lg-0">
       
            <li className="nav-item">
              <Link className="nav-link active" to="/" onClick={closeNavbar}>
                {t('home')}
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link active"
                to="/about"
                onClick={closeNavbar}
              >
                {t('about')}
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/services" onClick={closeNavbar}>
                {t('services')}
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/cities" onClick={closeNavbar}>
                {t('cities')}
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/contact" onClick={closeNavbar}>
                {t('contact')}
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link"
                to="tel:0661585885"
                onClick={closeNavbar}
              >
                <BsFillTelephoneFill /> 0661585885
              </Link>
          
            </li>
              
          </ul>

        
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
