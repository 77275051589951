import React, { useState } from "react";
import "./Contact.css";
import * as emailjs from '@emailjs/browser';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Contact = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    depart: "",
    destination: "",
    date: "",
    heure: "",
    passengers: "",
    phone: "",
    countryCode: "+33",
    message: "",
    name: "",
    lastname: "",
    trainnumber: "",
    flightnumber: "",
    suitcases: "",
    email: "", // ✅ Email added
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (isSubmitting) return;

    setIsSubmitting(true);
    const service_id = process.env.REACT_APP_EMAILJS_SERVICE_ID;
    const template_id = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
    const publicKey = process.env.REACT_APP_EMAILJS_USER_ID;

    const templateParams = {
      to_name: "Taxiscab",
      from_name: formData.name,
      from_email: formData.email, // ✅ Customer's email
      reply_to: formData.email,
      // ✅ So you can reply to it
    
      phone: `${formData.countryCode} ${formData.phone}`,
      depart: formData.depart,
      destination: formData.destination,
      name: formData.name,
      lastname: formData.lastname,
      trainnumber: formData.trainnumber,
      flightnumber: formData.flightnumber,
      date: formData.date,
      heure: formData.heure,
      passengers: formData.passengers,
      suitcases: formData.suitcases,
      message: formData.message,
    };

    emailjs
      .send(service_id, template_id, templateParams, publicKey)
      .then((response) => {
        console.log("Réservation envoyée avec succès:", response);
        setIsSubmitted(true);
        setIsSubmitting(false);
        setFormData({
          depart: "",
          destination: "",
          name: "",
          lastname: "",
          flightnumber: "",
          trainnumber: "",
          date: "",
          heure: "",
          passengers: "",
          suitcases: "",
          countryCode: "+33",
          phone: "",
          email: "", // ✅ Reset email
          message: "",
        });

        navigate("/merci");
        alert("Votre réservation a été soumise avec succès !");
      })
      .catch((error) => {
        console.error("❌ Erreur lors de l'envoi :", error);
        setIsSubmitting(false);
      });
  };

  return (
    <div className="container pt-5">
      <p className="text-white bg-info rounded-pill w-50 mt-5 p-2 m-auto fw-bold mb-4">
        {t("Réserver votre Course")}
      </p>
      <p className="text-info">{t("Réservation simple et rapide")}</p>
      <form className="reservation-form m-auto" onSubmit={handleSubmit}>
        {[
          "depart", "destination", "name", "email", "lastname", "trainnumber", "flightnumber",
          "date", "heure", "passengers", "suitcases", "message"
        ].map((field) => (
          <div className="form-group" key={field}>
            <label>{t(field)}</label>
            <input
              type={
                field === "date"
                  ? "date"
                  : field === "heure"
                  ? "time"
                  : field === "email"
                  ? "email"
                  : "text"
              }
              name={field}
              className="form-control"
              placeholder={t(`placeholder.${field}`)}
              value={formData[field]}
              onChange={handleChange}
              required={!["lastname", "trainnumber", "flightnumber", "message"].includes(field)}
            />
          </div>
        ))}

        {/* 📞 Phone number with country code */}
        <div className="form-group">
          <label>{t("phone")}</label>
          <div className="d-flex">
            <select
              name="countryCode"
              className="form-select me-2"
              value={formData.countryCode}
              onChange={handleChange}
              style={{ maxWidth: "180px", height: "40px" }}
              required
            >
              <option value="">-- Code --</option>
              <option value="+43">🇦🇹 Austria (+43)</option>
              <option value="+32">🇧🇪 Belgium (+32)</option>
              <option value="+359">🇧🇬 Bulgaria (+359)</option>
              <option value="+385">🇭🇷 Croatia (+385)</option>
              <option value="+357">🇨🇾 Cyprus (+357)</option>
              <option value="+420">🇨🇿 Czech Republic (+420)</option>
              <option value="+45">🇩🇰 Denmark (+45)</option>
              <option value="+372">🇪🇪 Estonia (+372)</option>
              <option value="+358">🇫🇮 Finland (+358)</option>
              <option value="+33">🇫🇷 France (+33)</option>
              <option value="+49">🇩🇪 Germany (+49)</option>
              <option value="+30">🇬🇷 Greece (+30)</option>
              <option value="+36">🇭🇺 Hungary (+36)</option>
              <option value="+354">🇮🇸 Iceland (+354)</option>
              <option value="+353">🇮🇪 Ireland (+353)</option>
              <option value="+39">🇮🇹 Italy (+39)</option>
              <option value="+371">🇱🇻 Latvia (+371)</option>
              <option value="+370">🇱🇹 Lithuania (+370)</option>
              <option value="+352">🇱🇺 Luxembourg (+352)</option>
              <option value="+356">🇲🇹 Malta (+356)</option>
              <option value="+373">🇲🇩 Moldova (+373)</option>
              <option value="+377">🇲🇨 Monaco (+377)</option>
              <option value="+382">🇲🇪 Montenegro (+382)</option>
              <option value="+31">🇳🇱 Netherlands (+31)</option>
              <option value="+47">🇳🇴 Norway (+47)</option>
              <option value="+48">🇵🇱 Poland (+48)</option>
              <option value="+351">🇵🇹 Portugal (+351)</option>
              <option value="+40">🇷🇴 Romania (+40)</option>
              <option value="+7">🇷🇺 Russia (+7)</option>
              <option value="+381">🇷🇸 Serbia (+381)</option>
              <option value="+421">🇸🇰 Slovakia (+421)</option>
              <option value="+386">🇸🇮 Slovenia (+386)</option>
              <option value="+34">🇪🇸 Spain (+34)</option>
              <option value="+46">🇸🇪 Sweden (+46)</option>
              <option value="+41">🇨🇭 Switzerland (+41)</option>
              <option value="+90">🇹🇷 Turkey (+90)</option>
              <option value="+380">🇺🇦 Ukraine (+380)</option>
              <option value="+44">🇬🇧 United Kingdom (+44)</option>
              <option value="other">🌍 {t("Other")}</option>
            </select>

            <input
              type="tel"
              name="phone"
              className="form-control"
              placeholder={t("placeholder.phone")}
              value={formData.phone}
              onChange={handleChange}
              required
            />
          </div>
        </div>

        <button type="submit" className="btn btn-outline-dark btn-block">
          {t("Réserver maintenant")}
        </button>
      </form>
    </div>
  );
};

export default Contact;
